<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="150px" :inline="true">
                <el-form-item label="PV正常状态" prop="pvOkCondition">
                    <el-select v-model="dataForm.pvOkCondition">
                        <el-option v-for="item in pvOptions" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="btn-box">
            <el-button @click="submit" type="primary">设置</el-button>
        </div>
    </div>
</template>

<script>

import loading from '@/utils/cmdLoading';
import mqttService from '@/api/mqtt-service';
import message from '@/utils/message';
import global from '@/components/Global';

export default {

    name: 'pv-ok-condition',
    data() {

        return {

            command: {

                commandCodeR: 'get_rate_info',
                commandCodeW: 'set_pv_ok_condition'
            },
            deviceSn: '',
            dataForm: {

                pvOkCondition: 0
            },
            pvOptions: [
                {
                    name : '至少一台逆变器连接光伏',
                    value: 0
                },
                {
                    name : '所有逆变器都连接光伏',
                    value: 1
                }
            ]
        }
    },
    methods: {

        /**
         * 页面初始化.
         */
        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('读取中...', 30);
            let that = this;
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        that.initSuccessCallBack(res.data);
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
        },
        /**
         * 设置提交.
         */
        submit() {

            this.cmdLoading = loading.loading('指令下发中...', 30);
            let that = this;
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeW,
                    data: that.dataForm
                },
                mqttMessageCallback: res => {

                    that.submitSuccessCallback(res);
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
        },

        /**
         * 查询指令回调.
         */
        initSuccessCallBack(data) {

            this.dataForm.pvOkCondition = parseInt(data.pvOkConditionForParallel);
        },
        /**
         * 设置指令回调.
         * @param data 指令响应数据
         */
        submitSuccessCallback(data) {

            if (data.code === global.response_status_success_obj) {

                message.success('PV正常状态设置成功');
            } else {

                message.error('指令下发失败');
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 1% 5%
}

.btn-box {

    text-align: center;
}
</style>
